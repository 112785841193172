<template>
    <div v-if="showButton" class="text-center"> 
      <v-btn @click.stop="addRecurringTransactions()" color="primary" :loading="this.isLoading" >
        <v-icon>mdi-plus</v-icon>
        <span>Add Recurring Transactions</span>
      </v-btn>
    </div>
</template>

<script>
import budgetService from "@/services/budget.service.js";
import {mapActions} from 'vuex';

export default {
    name: "AddRecurringTransactions",

    props: [        
        "startDate",
    ],
    components: {
    }, 

    data: () => ({
        isLoading: false,
        showButton: false,
    }),
    computed: {
        
    },

    methods: {
        ...mapActions("MessageHubModule", ["sendRecurringBudgetTransactionsAdded"]),
        async addRecurringTransactions() {
            if (!this.startDate) {
                return;
            }

            try {
                this.isLoading = true;
                await budgetService.addRecurringTransactions(this.startDate);
                this.sendRecurringBudgetTransactionsAdded(this.startDate);
                this.$emit("recurringTransactionsGenerated");
            } finally {
                await this.getAddRecurringTransactionsVisibility();
                this.isLoading = false;
                
            }
        },
        async getAddRecurringTransactionsVisibility() {
            if (!this.startDate) {
                this.showButton = false;
            } else {
                const resp = await budgetService.getAddRecurringTransactionsVisibility(this.startDate);
                this.showButton = resp.data;
            }
        }
    },
    mounted() {
        this.getAddRecurringTransactionsVisibility();
    },
    watch: {
        startDate() {
            this.getAddRecurringTransactionsVisibility();
        }
    }

}
</script>
