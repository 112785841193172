<template>
  <v-dialog :value="transaction" persistent max-width="1000px" :fullscreen="isSmallScreen" :hide-overlay="isSmallScreen" tile height="100%">
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="save()" height="100%">
      <v-card class="allow-for-bottom-menu" tile height="100%">
        <v-card-title>
          <div class="headline">{{ transaction.id ? "Edit" : "New" }}&nbsp;Recurring Transaction</div>
        </v-card-title>

        <v-card-text class="pa-2">
            <!-- Large Screen -->
            <div v-if="!isSmallScreen" class="d-flex align-start flex-wrap justify-center pb-15">              
              <div class="date-picker text-center px-6">
                <div class="title text-center mb-2">Starting Date</div>
                <v-date-picker
                  v-model="transaction.startDate"
                  class="pa-2 date-picker mb-4"
                  elevation="6"
                />

                <div class="title text-center">Recurs every...</div>
                <div class="d-flex px-6">
                  <v-text-field type="number" placeholder="Number" v-model="transaction.recurranceNumber" />

                  <v-select
                    v-model="transaction.recurranceTypeId"
                    :items="recurranceTypes"
                    item-value="id"
                    item-text="label"
                    label=""
                    required
                  />
                </div>  
              </div>                

               <v-sheet class="pa-2 detail justify-content-center d-flex flex-column">
                <v-select
                  v-model="transaction.budgetCategoryId"
                  :items="budgetCategories"
                  item-value="id"
                  item-text="name"
                  :rules="budgetCategoryRules"
                  label="Category"
                  required
                />

                <v-radio-group v-model="transaction.transactionTypeId" row>                
                  <v-radio v-for="type in transactionTypes" :key="type.id" :label="type.name" :value="type.id" />
                </v-radio-group>

                <div >
                  <v-text-field
                    v-model="transaction.description"
                    :rules="descriptionRules"
                    ref="description"
                    label="Description"
                    :autofocus="!!transaction"
                    required
                  />

                  <v-text-field
                    v-model="transaction.amount"
                    type="number"
                    :rules="amountRules"
                    label="Amount"
                    required
                  />

                  <v-text-field
                    v-model="transaction.memo"
                    type="text"
                    label="Memo"
                  />

                  <v-checkbox v-model="transaction.isAutopay" label="Autopay" />
                  
                  <v-checkbox v-model="transaction.isActive" label="Active" />
                </div>
              </v-sheet>
            </div>
            <!-- Small Screen -->
            <template v-else>
              <v-stepper v-model="stepPosition" class="elevation-0">
                <v-stepper-header class="mb-0 elevation-0">
                  <v-stepper-step step="1" editable></v-stepper-step>
                  <v-divider></v-divider>                
                  
                  <v-stepper-step step="2" editable></v-stepper-step>
                  <v-divider></v-divider>

                  <v-stepper-step step="3" editable></v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                  <v-stepper-content step="1" class="pa-2">
                    <v-sheet max-width="400px" class="mx-auto">
                      <!-- Budget Category -->
                      <div class="title text-center">Category</div>
                      <v-select
                        v-model="transaction.budgetCategoryId"
                        :items="budgetCategories"
                        item-value="id"
                        item-text="name"
                        :rules="budgetCategoryRules"
                        label="Select Category"
                        required
                      ></v-select>

                      <!-- Transaction Type -->
                      <v-radio-group v-model="transaction.transactionTypeId" row class="mt-6">                
                        <v-radio v-for="type in transactionTypes" :key="type.id" :label="type.name" :value="type.id" class="mx-auto" />
                      </v-radio-group>  

                      <!-- Recurrance -->
                      <div class="title text-center mt-6">Recurs every...</div>
                      <div class="d-flex px-8">
                        <v-text-field type="number" placeholder="Number" v-model="transaction.recurranceNumber"></v-text-field>

                        <v-select
                            v-model="transaction.recurranceTypeId"
                            :items="recurranceTypes"
                            item-value="id"
                            item-text="label"
                            label=""
                            required
                        ></v-select>     
                      </div>    
                    </v-sheet>                  
                  </v-stepper-content>

                  <v-stepper-content step="2" class="pa-3 text-center">
                    <v-sheet max-width="400px" class="mx-auto">
                      <div class="title text-center">Starting Date</div>

                      <!-- Transaction Date -->
                      <v-date-picker v-model="transaction.startDate" elevation="6" class="mb-3" />
                    </v-sheet>
                  </v-stepper-content>

                  <v-stepper-content step="3" class="pa-3">
                    <v-sheet max-width="400px" class="mx-auto">
                      <div class="title text-center">Details</div>
                    
                      <!-- Description -->
                      <v-text-field
                        ref="description"
                        v-model="transaction.description"
                        :rules="descriptionRules"
                        label="Description"
                        required
                        :autofocus="!!transaction"
                        class="mb-4"
                      ></v-text-field>

                      <!-- Amount -->
                      <v-text-field
                        v-model="transaction.amount"
                        type="number"
                        :rules="amountRules"
                        label="Amount"
                        prefix="$"
                        required
                      ></v-text-field>

                      <!-- Memo -->
                      <v-text-field
                        v-model="transaction.memo"
                        type="text"
                        label="Memo"
                        class="mx-auto mb-4"
                      ></v-text-field>

                      <div class="px-3">
                        <v-checkbox v-model="transaction.isAutopay" label="Autopay" />
                      </div>
                      
                      <div class="px-3">
                        <v-checkbox v-model="transaction.isActive" label="Active" />
                      </div>

                    </v-sheet>
                  </v-stepper-content>                  
                </v-stepper-items>
              </v-stepper>           
            </template>        
        </v-card-text>
      </v-card>

      <v-bottom-navigation :app="isSmallScreen" background-color="#eee" class="elevation-0">    
        <template v-if="isSmallScreen">
          <v-btn text @click="stepPosition--" :disabled="stepPosition <= 1">
            <span>Previous</span>
            <v-icon>mdi mdi-menu-left</v-icon> 
          </v-btn>
          <v-btn text color="blue darken-1" @click="stepPosition++" :disabled="stepPosition >= 3">
            <span>Next</span>
            <v-icon>mdi mdi-menu-right</v-icon>
          </v-btn>   

          <v-spacer />
        </template>


        <v-btn text @click="close()" :disabled="isSaving">
          <span>Cancel</span>
          <v-icon>mdi mdi-window-close</v-icon>
        </v-btn>

        <v-btn type="submit" text :disabled="!valid" color="primary" :loading="isSaving">
          <span>Save</span>
          <v-icon>mdi mdi-content-save</v-icon>
        </v-btn>      
      </v-bottom-navigation>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import recurringTransactionsService from "@/services/recurring-transaction.service.js";
import geolocation from '@/utils/geolocation.js';
import toasterHandler from "@/utils/toaster.handler.js";

export default {
  name: "TransactionEntryDialog",

  props: ["value", "payPeriodDate"],

  components: {},

  data: () => ({
    transaction: "",

    transactionDatePicker: false,
    transactionDateFormatted: "",
    recurranceTypes: [
      { id: 1, label: 'Month(s)' },
      { id: 2, label: 'Day(s)' },
    ],

    transactionDateRules: [(td) => !!td || "Transaction date is required"],
    descriptionRules: [(d) => !!d || "Description is required"],
    amountRules: [(a) => !!a || "Amount is required"],
    budgetCategoryRules: [(bc) => !!bc || "Category is required"],
    transactionTypeRules: [(tt) => !!tt || "Type is required"],

    valid: false,
    isSaving: false,

    stepPosition: 1
  }),

  computed: {
    ...mapState("BudgetModule", [
      "transactionTypes",
      "budgetCategories",
      "transactionStatuses",
    ]),
    isSmallScreen() {
      const screenSizes = ['xs','sm'];
      return screenSizes.indexOf(this.$vuetify.breakpoint.name) >= 0;
    }
  },
  methods: {   
    close() {
      this.stepPosition = 1;
      this.$refs.form.reset();
      this.transaction = '';
      this.$emit("input", '');
    },
    async save() {
      try {
        this.isSaving = true;
        this.$refs.form.validate();
        if (this.valid) {          
          if (this.transaction.id > 0) {
            await recurringTransactionsService.updateRecurringTransaction(this.transaction);   
            toasterHandler.showToast('success', 'mdi-pencil', null, "Recurring transaction updated", null, null, null);       
          } else {            
            await recurringTransactionsService.addRecurringTransaction(this.transaction);   
            toasterHandler.showToast('success', 'mdi-plus', null, "Recurring transaction added", null, null, null);
          }

          this.$emit("saved");
          this.close();          
        }
      } finally {
        this.isSaving = false;
      }
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    focus() {
      if (this.transaction && !this.transaction.description) {
        this.$refs.description.$el.focus();
      }
    },
    async setTransactionCoords() {
      console.log('get loc');
      const coords = await geolocation.getLocation();
      if (coords) {
        console.log('setting location');
        this.transaction.createdLocationLatitude = coords.latitude;
        this.transaction.createdLocationLongitude = coords.longitude;
      }
    },
  },
  watch: {
    value: {
      handler(val) {
        if (!val) {
          return;
        }
        val.startDate = new Date(val.startDate)
          .toISOString()
          .substr(0, 10);
        this.transaction = { ...val };
      },
      deep: true,
    },
    isSmallScreen() {
      this.focus();
    }
  },
  mounted() {
    this.startDateFormatted = this.transaction.startDate
      ? this.formatDate(
          this.transaction.startDate.toISOString().substr(0, 10)
        )
      : this.formatDate(new Date().toISOString().substr(0, 10));

    this.focus();
  },
};
</script>


<style scoped>
.date-picker {
  flex-basis: 400px;
}
.date-picker .v-picker--date {
  min-height: 380px;
}

.detail {
  flex-basis: 400px;
}
.v-dialog {
  position: relative;
}

.v-stepper__content .title {
  font-size: 1.4rem;
}


.v-btn-toggle .v-btn {
  width:70px;
}

.previous-next button{
  width: 120px;
}

form {
  height: 100%;
}
</style>
