<template>
    <v-sheet color="white" elevation="1" class="px-4 py-8 mx-auto" max-width="800px">
        <h1>Search</h1>
        <v-form @submit.prevent="searchTransactions()" autocomplete="off">
            <v-text-field label="Keywords" v-model="searchText" clearable hide-details />    
            <v-alert v-if="!searchText" dense outlined type="info" class="mt-2 mb-3">Search with no keywords is limited to 500 results</v-alert>

            <div class="d-flex justify-center py-2 mb-2">
                <v-text-field v-mask="'##/##/####'" v-model="startDate" label="Start Date" placeholder="MM/DD/YYYY" class="date-entry my-0 py-0 mx-2" hide-details /> 
                <v-text-field v-mask="'##/##/####'" v-model="endDate" label="End Date" placeholder="MM/DD/YYYY" class="date-entry my-0 py-0 mx-2" hide-details />
                <v-btn icon @click="clearDates()"><v-icon>mdi-close</v-icon></v-btn>
            </div>

            <div class="d-flex flex-wrap justify-center mt-2"> 
                <v-switch class="status-option" v-model="unplanned" hide-details>
                    <template v-slot:label>
                        <v-icon class="mr-1">mdi-border-none-variant</v-icon>
                        Planned
                    </template>
                </v-switch>                                                          
                              
                <v-switch class="status-option" v-model="sent" hide-details>
                    <template v-slot:label>
                        <v-icon class="mr-1">mdi-send</v-icon>
                        Sent
                    </template>                    
                </v-switch>

                <v-switch class="status-option" v-model="pending" label="Pending" hide-details>
                    <template v-slot:label> 
                        <v-icon class="mr-1">mdi-bank</v-icon>
                        Pending
                    </template>
                </v-switch>

                <v-switch class="status-option" v-model="cleared" label="Cleared" hide-details>
                    <template v-slot:label>
                        <v-icon class="mr-1">mdi-check</v-icon>
                        Cleared
                    </template>
                </v-switch>
            </div>                       
            
            <div class="d-flex justify-space-around mt-8">
                <v-btn type="button" class="cancel-button mr-2" @click.stop="closeSearch()" small width="100px">Cancel</v-btn>                
                <v-btn type="button" class="mx-2" @click.stop="viewMap()" small :disabled="!mapButtonEnabled | isLoading"><v-icon class="mr-1">mdi-map</v-icon>Map</v-btn>
                <v-btn type="submit" color="primary" class="search-button ml-2" small width="100px" :loading="isLoading">Search</v-btn>
            </div>
        </v-form>
    </v-sheet>
</template>

<script>
import {mask} from 'vue-the-mask';

export default {
  name: "BudgetSearch",
  directives: {mask},
  components: {}, 
  props: {
      mapButtonEnabled: {type: Boolean, default: true},
  },

  data: () => ({
      isLoading: false,
      searchText: '',
      unplanned: true,
      sent: true,
      pending: true,
      cleared: true,
      startDate: null,
      endDate: null,
  }),

  computed: {
  },

  methods: {
    closeSearch() {
        this.$emit("closeSearch");
    },
    async searchTransactions() {
        this.$emit("searchResults", {
            searchText: this.searchText, 
            startDate: this.startDate, 
            endDate: this.endDate, 
            unplanned: this.unplanned, 
            sent: this.sent, 
            pending: this.pending, 
            cleared:this.cleared
        });
    },
    viewMap() {
        this.$emit("viewMap");
    },
    clearDates() {
        this.startDate = null;
        this.endDate = null;
    }
  },
  watch: {

  },
  mounted() {
   
  },
};
</script>

<style scoped>
    .status-option {
        width: 165px;
        border: 1px solid #ccc;
        padding: 4px 8px;
        margin: 4px;
        border-radius: 10px;
    }

    .date-entry {
        max-width: 180px;
    }
</style>
