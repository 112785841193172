<template>
  <v-card class="mb-4">
    <v-toolbar flat v-if="category" class="card-heading">
      <v-toolbar-title class="text-left">
        <div>{{category.name}}</div>
        <div v-if="spendingLimit" class="ml-4 spending-limit">
          <strong :class="{debit: remainingBudget < 0}">{{remainingBudget | currency}}</strong>/{{spendingLimit.amount | currency}} Remaining
        </div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn class="mx-2" fab dark x-small color="primary" @click.stop="addTransaction()">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>

    
    <v-simple-table v-if="categoryTransactions.length > 0" class="elevation-1 mb-3">
      <thead>
        <tr>
          <th class="px-0 icon-column"><!-- Recurring Icon --></th>
          <th class="transaction-date text-center pl-1">Date</th>
          <th class="description text-left">Description</th>
          <th class="amount text-right">Amount</th>
          <th v-if="!isSmallScreen" class="memo text-left">Memo</th>
          <th class="status"><!-- Status --></th>
          <th class="actions"><!-- Actions --></th>
        </tr>
      </thead>

      <tbody>        
        <tr v-for="item in categoryTransactions" :key="item.id" :class="{cleared: isCleared(item)}">
          <!-- Recurring Icon -->
            <td class="px-0 icon-column">
              <v-tooltip left v-if="item.recurringTransactionId">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mx-1" color="primary" v-bind="attrs" v-on="on" small @click.stop="openRecurringTransaction(item.recurringTransaction)">
                    {{item.recurringTransaction.isAutopay ? 'mdi-cash-fast' : 'mdi-autorenew'}}
                  </v-icon>                    
                </template>
                <span>{{item.recurringTransaction.isAutopay ? 'Autopay' : 'Recurring'}}</span>
              </v-tooltip>
            </td>

            <!-- TransactionDate -->
            <td class="transaction-date text-center pl-1">
              <div v-if="!isSmallScreen">{{item.transactionDate | shortDate}}</div>
              <div v-else class="text-center">
                <div>{{item.transactionDate | monthDay}}</div>
                <div class="small-year">{{item.transactionDate | year}}</div>
              </div>
            </td>

            <!-- Description -->
            <td class="description text-left">{{item.description}}</td>

            

            <!-- Amount -->
            <td class="amount text-right">
              <span :class="{ debit: item.transactionTypeName === 'Debit', credit: item.transactionTypeName === 'Credit'}">{{item.transactionTypeName === 'Debit' ? -item.amount : item.amount | currency}}</span>
            </td>
            
            <!-- Memo -->
            <td class="memo text-left" v-if="!isSmallScreen">{{item.memo}}</td>

            <!-- Status  -->
            <td class="status">
              <v-menu close-on-content-click :class="{disabled: isCleared(item)}">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" :loading="statusLoading(item)" :disabled="isCleared(item)" >
                    <v-icon>{{getStatusIcon(item)}}</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click="updateTransactionStatus(item, TRANSACTION_STATUSES.planned)">
                    <v-list-item-title>
                      <v-icon>mdi-border-none-variant</v-icon>
                      <span class="ml-4 vertical-align-middle">Planned</span>
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item @click="updateTransactionStatus(item, TRANSACTION_STATUSES.sent)">
                    <v-list-item-title>
                      <v-icon>mdi-send</v-icon>
                      <span class="ml-4 vertical-align-middle">Sent</span>
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item @click="updateTransactionStatus(item, TRANSACTION_STATUSES.pend)">
                    <v-list-item-title>
                      <v-icon>mdi-bank</v-icon>
                      <span class="ml-4 vertical-align-middle">Pending</span>
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item @click="updateTransactionStatus(item, TRANSACTION_STATUSES.clr)">
                    <v-list-item-title>
                      <v-icon>mdi-check</v-icon>
                      <span class="ml-4 vertical-align-middle">Cleared</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>

            <td class="actions">
              <v-menu close-on-content-click bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click="openTransactionDetail(item)">
                    <v-list-item-title>
                      <v-icon>mdi-information</v-icon>
                      <span class="ml-4 vertical-align-middle">Info</span>
                    </v-list-item-title>
                  </v-list-item>

                  <v-divider></v-divider>

                  <v-list-item @click="editTransaction(item)">
                    <v-list-item-title>
                      <v-icon>mdi-pencil</v-icon>
                      <span class="ml-4 vertical-align-middle">Edit</span>
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item link color="#ff0000" @click="confirmDeleteTransaction(item)">
                    <v-list-item-title>
                      <v-icon color="red">mdi-delete</v-icon>
                      <span class="ml-4 vertical-align-middle red--text">Delete</span>
                    </v-list-item-title>
                  </v-list-item>              
                </v-list>
              </v-menu>
            </td>
        </tr>
      </tbody>

      <tfoot>
        <tr>
          <td colspan="3" class="text-left font-weight-bold pl-6">
            <div>Total:</div>
          </td>
          <td class="text-right">
            <div :class="{debit: (creditSum - debitSum) < 0, credit: (creditSum - debitSum) > 0}">{{(creditSum - debitSum) | currency}}</div>
          </td>
          <td colspan="3"><!-- Memo, Status, Actions --></td>
        </tr>        
      </tfoot>
    </v-simple-table>

    <!-- Delete Transaction Confirmation Dialog -->
    <ConfirmDialog 
      v-model="transactionToDelete" 
      color="error" 
      dark
      title="Delete Transaction?" 
      submitBtnTitle="Delete" 
      :loading="isDeleting" 
      @confirm="deleteTransaction($event)"
    >
      <div class="delete-transaction-details">
        <div class="detail-group">
          <label>Date: </label>
          <span>{{ transactionToDelete.transactionDate | shortDate }}</span>
        </div>
        <div class="detail-group">
          <label>Description: </label>
          <span>{{ transactionToDelete.description }}</span>
        </div>
        <div class="detail-group">
          <label>Amount: </label>
          <span>{{ transactionToDelete.amount | currency }}</span>
        </div>
        <div class="detail-group">
          <label>Category: </label>
          <span>{{ transactionToDelete.budgetCategoryName }}</span>
        </div>
        <div class="detail-group">
          <label>Type: </label>
          <span>{{ transactionToDelete.transactionTypeName }}</span>
        </div>
      </div>
    </ConfirmDialog>
  </v-card>

</template>

<script>
import budgetService from '@/services/budget.service.js';
import ConfirmDialog from '@/components/Core/ConfirmDialog.vue';
import moment from 'moment';
import {mapActions, mapGetters} from 'vuex';
import { TRANSACTION_STATUSES } from '@/constants/transaction-statuses.js';
import toasterHandler from "@/utils/toaster.handler.js";

export default {
  name: "TransactionList",

  props: [
    'transactions',
    'category',
    'transactionTypes',
    'transactionStatuses',
    'budgetCategories',
    'payPeriodDate',
    'spendingLimit'
  ],
  components: {
    ConfirmDialog,
  }, 

  data: () => ({
    TRANSACTION_STATUSES: TRANSACTION_STATUSES,
    loadingTransactions: [],

    transactionToDelete: '',
    entryFormTransaction: '',
    
    isDeleting: false,
  }),

  computed: {
    ...mapGetters("MessageHubModule", ["isSocketConnected", "socketConnectionState"]),
    isSmallScreen() {
      const screenSizes = ['xs','sm'];
      return screenSizes.indexOf(this.$vuetify.breakpoint.name) >= 0;
    },
    debitSum() {
      return this.categoryTransactions
        .filter(t => t.transactionTypeName === 'Debit')
        .reduce((a,b) => a + b.amount, 0);
    },
    creditSum() {
      return this.categoryTransactions
        .filter(t => t.transactionTypeName === 'Credit')
        .reduce((a,b) => a + b.amount, 0);
    },
    remainingBudget() {
      return this.spendingLimit.amount + (this.creditSum - this.debitSum);
    },
    categoryTransactions() {
      return this.transactions.filter(t => !this.category || t.budgetCategoryId == this.category.id);
    }
  },
  methods: {
    ...mapActions("BudgetModule", ["setEntryFormTransaction"]),
    ...mapActions("MessageHubModule", [
      "sendBudgetTransactionDeleted",
      "sendBudgetTransactionEdited",
    ]), 
    getStatusIcon(transaction) {      
      switch (transaction.transactionStatusName) {
        case 'Planned': return 'mdi-border-none-variant';
        case 'Sent': return 'mdi-send';
        case 'Pend': return 'mdi-bank';
        case 'Clr': return 'mdi-check';
        default: return 'mdi-border-none-variant';
      }
    },
    isCleared(transaction) {
      return transaction.transactionStatusName === 'Clr';
    }, 
    async updateTransactionStatus(transaction, newStatusId) {
      this.setTransactionIsLoading(transaction);
      transaction.transactionStatusId = newStatusId;

      try {
        let updatedTransaction = await budgetService.updateTransaction(transaction);
        this.sendBudgetTransactionEdited(transaction);
        toasterHandler.showToast('success', 'mdi-pencil', null, "Transaction status updated", null, null, null);
        this.$emit('transactionUpdated', updatedTransaction.data);
      } finally {
        this.setTransactionNotLoading(transaction);
      }
    },  
    async deleteTransaction(transaction) {
      console.log({delete: transaction})
      try {
        this.isDeleting = true;
        await budgetService.deleteTransaction(transaction);
        this.transactionToDelete = '';
        toasterHandler.showToast('success', 'mdi-delete-outline', null, "Transaction deleted", null, null, null);

        this.sendBudgetTransactionDeleted(transaction);
        this.$emit('transactionDeleted', transaction);
      } finally {
        this.isDeleting = false;
      }
    },  
    statusLoading(transaction) {
      const index = this.loadingTransactions.indexOf(transaction.id);
      return index >= 0;
    },
    setTransactionIsLoading(transaction) {
      this.loadingTransactions.push(transaction.id);
    },
    setTransactionNotLoading(transaction) {
      let index = this.loadingTransactions.indexOf(transaction.id);      
      this.loadingTransactions.splice(index, 1);
    },
    openTransactionDetail(transaction) {
      this.$emit('openDetail', transaction);
    },
    async addTransaction() {
      const selectedPayPeriodMoment = moment(this.payPeriodDate);
      
      const now = new Date();
      const currentPayPeriodMoment = now.getDate() < 15
          ? moment([now.getFullYear(), now.getMonth(), 1])
          : moment([now.getFullYear(), now.getMonth(), 15]);


      const isFuture = selectedPayPeriodMoment.isAfter(currentPayPeriodMoment);

      let newTransaction = {
        id:'',
        transactionDate: new Date().toDateString(),
        payPeriodDate: this.payPeriodDate,
        budgetCategoryId: this.category.id,
        transactionTypeId: this.category.defaultTransactionTypeId,
        transactionStatusId: isFuture ? TRANSACTION_STATUSES.planned : TRANSACTION_STATUSES.sent, // don't assume "Sent" for transactions added to future pay periods
        description: '',
        amount:'',
        memo: '',
        tipAmount: '',
        confirmationNumber: '',
      }

      this.setEntryFormTransaction(newTransaction );
    },
    editTransaction(transaction) {
      this.setEntryFormTransaction(transaction);
    },
    
    confirmDeleteTransaction(transaction) {
      this.transactionToDelete = transaction;
    },    
    openRecurringTransaction(recurringTransaction) {     
      console.log({EditRecurringTransaction: recurringTransaction}) ;
      this.$emit('editRecurringTransaction', recurringTransaction);
    }
  }
}
</script>


<style scoped>
  .debit {
    color: red;
  }

  .credit {
    color: green;
  }

  .vertical-align-middle {
    vertical-align: middle;
  }


  .card-heading {
    border-bottom: 1px solid #ccc;
    background-color: #f9f9f9 !important;
  }
  
  .delete-transaction-details .detail-group {
  margin: 5px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-size: 1rem;
  display: flex;
}

.delete-transaction-details .detail-group label {
  flex-basis: 110px;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: right;
  margin-right: 16px;
  font-weight: bold;
}

.delete-transaction-details .detail-group span {
  white-space: pre-wrap;
}

.transaction-date {
  width: 120px;
}

.description {
  min-width: 250px;
  white-space: nowrap;
}

.memo {
  width: 100%;
}

td.status,
td.actions {
  width: 80px;
  flex-wrap: nowrap;
  padding-left: 4px !important;
  padding-right:4px !important;
}
td.actions {
  padding-left:4px;
  padding-right:16px !important;
}

tfoot tr {
  background-color: #eee;
}

/* tfoot tr:not(.budget-balance) {
  background-color: #eee;
} */

tfoot tr td {
  border-top: 1px solid #ccc;
}

tr.budget-balance td {
    height: 24px !important;
}

.spending-limit {
  font-size: .8rem;
  vertical-align: middle;
  color: #555;
}

@media (max-width: 800px) {
  td,
  th {
    padding-left: 6px !important;
    padding-right: 4px !important;
  }

  th:first-of-type,
  td:first-of-type {
    padding-left: 10px;
  }

  th:last-of-type,
  td:last-of-type {
    padding-right: 10px;
  }

  .transaction-date {
    width: 54px;
  }

  th.status,
  td.status,
  th.actions,
  td.actions {
    max-width: 45px;
    width: 45px;
  }

  .description {
    min-width: 0;
  }

  td.description {
    text-align: left;
    max-width: 115px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
}
</style>

<style>
.v-date-picker-table {
  margin-bottom: 10px;
}

tr.cleared td:not(.actions) {
  opacity: .6;
}

.disabled {
  pointer-events: none;
}

.small-year {
  font-size: .8em;
}

td.icon-column {
  max-width: 20px;
}

</style>