<template>
  <v-sheet max-width="100%" class="text-center mx-auto" color="transparent">
    <template v-if="!showSearch">
      <BudgetDateSelector @dateSelected="onDateSelected($event)" @openSearch="openSearch()" @viewMap="showTransactionsMap()"/>

      <div class="pa-3">
        <BudgetSummary
          :transactions="budgetTransactions" 
          :spendLimits="spendingLimits" 
          :startingBalance="startingBalance" 
          :bankAccountBalance="bankAccountBalance" 
          :startDate="selectedTimeFrame.startDate" 
          class="pb-4" />

        <AddRecurringTransactions
          :startDate="selectedTimeFrame.startDate" 
          @recurringTransactionsGenerated="getTransactions()" 
          class="mx-auto mb-4" />

        <TransactionList
          v-for="category in budgetCategories"
          :key="category.id"
          :transactions="budgetTransactions"
          :category="category"
          :payPeriodDate="selectedTimeFrame.startDate"
          :spendingLimit="getCategorySpendingLimit(category.id)"
          @transactionUpdated="onTransactionSaved($event)"
          @transactionDeleted="onTransactionDeleted($event)"
          @openDetail="openTransactionDetail($event)"        
          @editRecurringTransaction="editRecurringTransaction($event)" />
      </div>
    </template>
    <template v-else>
      <div class="pa-3">
        <BudgetSearch 
          :mapButtonEnabled="budgetTransactions.length > 0" 
          @closeSearch="closeSearch()" 
          @searchResults="onSearch($event)" 
          @viewMap="showTransactionsMap()" 
          class="mb-4" 
        />

        <TransactionList
            :transactions="budgetTransactions"
            @transactionUpdated="onTransactionSaved($event)"
            @transactionDeleted="onTransactionDeleted($event)"
            @openDetail="openTransactionDetail($event)"        
            @editRecurringTransaction="editRecurringTransaction($event)"
          />
      </div>
    </template>

    <TransactionDetailModal v-model="viewDetailTransaction" @saved="onTransactionSaved($event)" />
    <TransactionEntryDialog @saved="onTransactionSaved($event)" />

    <RecurringTransactionFormDialog v-model="selectedRecurringTransaction" @saved="onRecurringTransactionSaved()" />

    <MapBoxDialog v-if="mapItems" :value="mapItems" :width="'80%'" :height="'80%'" @close="closeMap()" />
    
    <v-overlay :value="isLoadingTransactions">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-sheet>
</template>

<script>
import budgetService from "@/services/budget.service.js";
import BudgetDateSelector from "@/components/Budget/BudgetDateSelector.vue";
import TransactionList from "@/components/Budget/TransactionList.vue";
import BudgetSummary from "@/components/Budget/BudgetSummary.vue";
import BudgetSearch from "@/components/Budget/BudgetSearch.vue";
import TransactionDetailModal from "@/components/Budget/TransactionDetailModal.vue";
import TransactionEntryDialog from "@/components/Budget/TransactionEntryDialog.vue";
import AddRecurringTransactions from "@/components/Budget/AddRecurringTransactions.vue";
import MapBoxDialog from "@/components/MapBox/MapBoxDialog.vue";
import RecurringTransactionFormDialog from '@/components/Admin/Budget/RecurringTransactionFormDialog.vue';
import {mapState, mapGetters, mapActions} from "vuex";
import { format} from 'date-fns'
import moment from "moment";


export default {
  components: {
    BudgetDateSelector,
    TransactionList,
    TransactionDetailModal,
    TransactionEntryDialog,
    BudgetSummary,
    BudgetSearch,
    AddRecurringTransactions,
    MapBoxDialog,
    RecurringTransactionFormDialog
  },
  data: () => ({
    selectedTimeFrame: {},
    
    budgetTransactions: [],
    viewDetailTransaction: "",
    spendingLimits: [],
    startingBalance: 0,
    bankAccountBalance: 0,
    searchParams: '',
    showSearch: false,    
    mapItems: null,
    selectedRecurringTransaction: null,
    isLoadingTransactions: false,
  }),
  computed: {
    ...mapGetters("BudgetModule", ["transactionTypes", "budgetCategories", "transactionStatuses"]),
    ...mapState("OfflineModule", ["queuedRequests"])
  },
  methods: {
    ...mapActions("BudgetModule",["setTransactionTypes", "setBudgetCategories", "setTransactionStatuses"]),
    createdOffsetDateTime(transaction) {
      let dateTime = moment(transaction.createdDateTime);
      dateTime.add(-transaction.createdUtcOffset, "minutes");

      return `${dateTime.format("MMMM Do YYYY, h:mm:ss a")} ${
        transaction.createdTimeZone
      }`;
    },
    createdOffsetDate(transaction) {
      let dateTime = moment(transaction.createdDateTime);
      dateTime.add(-transaction.createdUtcOffset, "minutes");

      return `${dateTime.format("MMMM Do YYYY")}`;
    },
    createdOffsetTime(transaction) {
      let dateTime = moment(transaction.createdDateTime);
      dateTime.add(-transaction.createdUtcOffset, "minutes");

      return `${dateTime.format("h:mm:ss a")} ${transaction.createdTimeZone}`;
    },
    async onDateSelected(timeFrame) {
      this.selectedTimeFrame = timeFrame;
      this.getTransactions();
      this.getSpendingLimits();
    },
    async getTransactions() {
      try {
        this.isLoadingTransactions = true;

        let result = this.showSearch
          ? await budgetService.searchTransactions(this.searchParams.searchText, this.searchParams.startDate, this.searchParams.endDate, this.searchParams.unplanned, this.searchParams.sent, this.searchParams.pending, this.searchParams.cleared)
          : await budgetService.getTransactionsByDateRange(this.selectedTimeFrame.startDate, this.selectedTimeFrame.endDate);        

        this.budgetTransactions = result.data;
      } finally {
        this.isLoadingTransactions = false;
      }
    },
    openTransactionDetail(transaction) {
      this.viewDetailTransaction = transaction;
    },
    closeTransactionDetail() {
      this.viewDetailTransaction = '';
    },
    async onTransactionSaved(savedTransaction) {
      let existing = this.budgetTransactions.find(x => x.id === savedTransaction.id);

      if (existing) {
        let index = this.budgetTransactions.indexOf(this.budgetTransactions.find(x => x.id === savedTransaction.id));
        this.$set(this.budgetTransactions, index, savedTransaction);        
      } else {
        this.$set(this.budgetTransactions, this.budgetTransactions.length, savedTransaction)        
      }
      
      this.getBankBalance();
    },
    onTransactionDeleted(deletedTransaction) {
      let existing = this.budgetTransactions.find(x => x.id === deletedTransaction.id);

      if (existing) {
        const index = this.budgetTransactions.indexOf(existing);        
        this.budgetTransactions.splice(index, 1);
      }

      this.getBankBalance();
    },
    async getBankBalance() {
      const acctBalResponse = await budgetService.getBankAccountBalance();
      this.bankAccountBalance = acctBalResponse.data;
    },
    getCategorySpendingLimit(categoryId) {
      const spendingLimit = this.spendingLimits && this.spendingLimits.length > 0
        ? this.spendingLimits.filter(sl => sl.budgetCategoryId === categoryId)
        : [];
      
      return spendingLimit.length > 0 ? spendingLimit[0] : null;
    },
    async getSpendingLimits() {
      const response = await budgetService.getSpendingLimits(this.selectedTimeFrame.startDate);
      this.spendingLimits = response.data;
    },
    async getStartingBalance() {
        const startingBalResp = await budgetService.getStartingBalance(this.startDate);
        this.startingBalance = startingBalResp.data;
    },
    openSearch() {
      this.showSearch = true;
      this.searchParams = {};
      this.budgetTransactions = [];
    },
    async onSearch(searchParams) {
      try {
        this.searchParams = searchParams;

        const searchResponse = await budgetService.searchTransactions(searchParams.searchText, searchParams.startDate, searchParams.endDate, searchParams.unplanned, searchParams.sent, searchParams.pending, searchParams.cleared);
        this.budgetTransactions = searchResponse.data;
      } finally {
        this.isLoadingTransactions = false;
      }
    },
    showTransactionsMap() {
      this.mapItems = this.budgetTransactions
        .filter(t => t.createdLocationLatitude && t.createdLocationLongitude)
        .map(t => {
          return { 
            latitude: t.createdLocationLatitude, 
            longitude: t.createdLocationLongitude, 
            popup: {
              title: t.description,
              fields: [            
                { label: 'Date: ', value: format(new Date(t.transactionDate), 'MM/dd/yyyy')},
                { label: 'Amount: ', value: this.$options.filters.currency(t.amount) },
                { label: 'Tip: ', value: this.$options.filters.currency(t.tipAmount) },
                { label: 'Created: ', value: this.createdOffsetDate(t)},
                { label: '', value: this.createdOffsetTime(t)},
                { label: 'By: ', value: t.createdBy }
              ].filter(f => f.value !== null),
            }  
          };
      });
    },
    closeSearch() {
      this.showSearch = false;
      this.searchParams = '';
      this.budgetTransactions = [];
    },
    closeMap() {
      this.mapItems = null;
    },
    editRecurringTransaction(recurringTransaction) {
      this.selectedRecurringTransaction = {...recurringTransaction, memo: ''};
    },
    onRecurringTransactionSaved() {
      // Empty
    }
  },
  mounted() {
    this.setTransactionTypes();
    this.setBudgetCategories();
    this.setTransactionStatuses();
    this.getBankBalance();
  }
};
</script>

<style scoped>
</style>
