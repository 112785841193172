<template>
  <v-dialog 
    v-if="entryFormTransaction"
    :value="entryFormTransaction"
    persistent 
    :width="isSmallScreen ? '100%' : '80%'" 
    max-width="1000px"     
    tile
    scrollable
  >
      <v-card tile>
        <v-card-title>
          <div v-if="!isSmallScreen" class="headline">{{ transaction.id ? "Edit" : "New" }}&nbsp;Transaction</div>         

          <div v-else class="d-flex justify-space-between full-width">
              <div>
                <div class="category-name">{{getBudgetCategoryName(transaction.budgetCategoryId)}}</div>
                <div class="transaction-date">{{transaction.transactionDate | shortDate}}</div>
              </div>
              <v-spacer />
              <div>
                <v-checkbox v-model="showFullForm" label="Full Form" hide-details/>
              </div>
            </div>
        </v-card-title>

        <v-divider />

        <v-card-text>
          <v-form ref="form" id="form" v-model="isValid" lazy-validation @submit.prevent="save()">
            <v-row>
              <v-col v-if="showAllFields" cols="12" md="5" lg="5" class="text-center py-4">
                <!-- Transaction Date -->
                <v-date-picker
                  v-model="transaction.transactionDate"
                  class="pa-2 date-picker"
                  elevation="1"
                ></v-date-picker>
              </v-col>

              <v-col cols="12" md="7" lg="7" class="pt-6">
                <!-- Budget Category -->
                <v-select
                  v-if="showAllFields"
                  required
                  v-model="transaction.budgetCategoryId"
                  :items="budgetCategories"
                  item-value="id"
                  item-text="name"
                  :rules="budgetCategoryRules"
                  label="Category"
                  outlined
                  dense
                ></v-select>                

                <!-- Transaction Type -->
                <div class="d-flex justify-center mb-4">
                  <v-radio-group                     
                    v-model="transaction.transactionTypeId" 
                    row 
                    class="justify-center mt-0" 
                    hide-details>                
                      <v-radio v-for="type in transactionTypes" :key="type.id" :label="type.name" :value="type.id" />
                  </v-radio-group>
                </div>
                
                <!-- Description -->
                <v-text-field
                  required
                  v-model="transaction.description"
                  :rules="descriptionRules"
                  ref="description"
                  label="Description"
                  :autofocus="!!entryFormTransaction"
                  outlined
                  dense
                ></v-text-field>

                <!-- Amount -->
                <v-text-field
                  required
                  v-model="transaction.amount"
                  type="number"
                  autocomplete="off"
                  :rules="amountRules"
                  label="Amount"
                  hint="Full amount (including tip)"
                  prepend-inner-icon="mdi-currency-usd"
                  outlined
                  dense
                ></v-text-field>
                
                <!-- Memo -->
                <v-text-field                  
                  v-model="transaction.memo"
                  type="text"
                  autocomplete="off"
                  label="Memo"
                  outlined
                  dense
                ></v-text-field>
                
                <div class="px-4">
                <v-card outlined :elevation="2">
                  <v-card-text v-if="tipAmountIsVisible || confirmationNumberIsVisible || checkNumberIsVisible">
                    <!-- Tip -->
                    <v-text-field
                      v-if="tipAmountIsVisible"
                      required
                      v-model="transaction.tipAmount"
                      type="number"
                      autocomplete="off"
                      label="Tip"
                      hint="Tip amount (if applicable)"
                      prepend-inner-icon="mdi-currency-usd"
                      outlined
                      dense
                      hide-details
                      class="mb-2"
                    ></v-text-field>

                    <!-- Confirmation Number -->
                    <v-text-field
                      v-if="confirmationNumberIsVisible"
                      required
                      v-model="transaction.confirmationNumber"
                      type="string"
                      autocomplete="off"
                      label="Confirmation Code"
                      outlined
                      dense
                      hide-details
                      class="mb-2"
                    ></v-text-field>
                    
                    <!-- Check Number -->
                    <v-text-field
                      v-if="checkNumberIsVisible"
                      required
                      v-model="transaction.checkNumber"
                      type="number"
                      autocomplete="off"
                      label="Check Number"                  
                      outlined
                      dense
                      hide-details
                      class="mb-2"
                    ></v-text-field>
                  </v-card-text>

                  <v-card-actions v-if="!tipAmountIsVisible || !confirmationNumberIsVisible || !checkNumberIsVisible">
                    <div class="d-flex full-width justify-space-between">                  
                      <v-btn v-if="!tipAmountIsVisible" @click.stop="showTipAmount = true" small color="primary">Tip</v-btn>
                      <v-btn v-if="!confirmationNumberIsVisible" @click.stop="showConfirmationNumber = true" small color="primary">Conf. #</v-btn>
                      <v-btn v-if="!checkNumberIsVisible" @click.stop="showCheckNumber = true" small color="primary">Check #</v-btn>
                    </div>
                  </v-card-actions>
                </v-card>
                </div>

                <!-- Transaction Status -->        
                <div class="mt-4 text-left" v-if="!isSmallScreen">
                  <h3 class="pb-0 mb-2 text-left">Transaction Status</h3>
                  <v-btn-toggle v-model="transaction.transactionStatusId" borderless color="primary" dense>
                    <v-btn v-for="status in transactionStatusesList" :key="status.id" :value="status.id" class="px-2 py-1">
                      <div>{{status.name}}</div>                      
                      <v-icon right v-if="status.id === 1">mdi-border-none-variant</v-icon>
                      <v-icon right v-if="status.id === 2">mdi-send</v-icon>
                      <v-icon right v-if="status.id === 3">mdi-bank</v-icon>
                      <v-icon right v-if="status.id === 4">mdi-check</v-icon>
                    </v-btn>
                  </v-btn-toggle>                  
                </div>
                <div v-else-if="showAllFields" class="mt-4">                  
                  <v-card class="mx-auto" max-width="200" tile>
                    <v-list dense>
                      <v-subheader>Transaction Status</v-subheader>
                      <v-list-item-group v-model="transaction.transactionStatusId" color="primary">
                        <template v-for="status in transactionStatusesList">                          
                          <v-list-item :key="status.id" :value="status.id">
                            <template v-slot:default="{ active }">
                              <v-list-item-action class="my-0 mr-3">
                                <v-checkbox :input-value="active" />
                              </v-list-item-action>

                              <v-list-item-content class="text-left d-flex">
                                <v-list-item-title v-text="status.name" />                              
                              </v-list-item-content>                            
                              
                              <v-list-item-icon>
                                <v-icon v-if="status.id === 1">mdi-border-none-variant</v-icon>
                                <v-icon v-if="status.id === 2">mdi-send</v-icon>
                                <v-icon v-if="status.id === 3">mdi-bank</v-icon>
                                <v-icon v-if="status.id === 4">mdi-check</v-icon>
                              </v-list-item-icon>
                            </template>
                          </v-list-item>
                        </template>
                      </v-list-item-group>
                    </v-list>
                  </v-card>
                </div>                
              </v-col>
            </v-row>                           
          </v-form>
        </v-card-text>    
        <v-divider />    
        <v-card-actions class="d-flex justify-center">
          <v-btn text @click="close()" :disabled="isSaving" class="mx-2">
            <span>Cancel</span>
            <v-icon>mdi mdi-window-close</v-icon>
          </v-btn>

          <v-btn text type="submit" form="form" color="primary" :loading="isSaving" class="mx-2">
            <span>Save</span>
            <v-icon>mdi mdi-content-save</v-icon>
          </v-btn>      
        </v-card-actions>  
        
      </v-card>      
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import budgetService from '@/services/budget.service.js';
import geolocation from '@/utils/geolocation.js';
import toasterHandler from "@/utils/toaster.handler.js";

export default {
  name: "TransactionEntryDialog",

  props: ["value", "payPeriodDate"],

  components: {},

  data: () => ({
    showFullForm: false,
    showCheckNumber: false,
    showTipAmount: false,
    showConfirmationNumber: false,
    transaction: "",

    transactionDate: "",
    transactionDatePicker: false,

    transactionDateRules: [(td) => !!td || "Transaction date is required"],
    descriptionRules: [(d) => !!d || "Description is required"],
    amountRules: [(a) => !!a || "Amount is required"],    
    budgetCategoryRules: [(bc) => !!bc || "Category is required"],
    transactionTypeRules: [(tt) => !!tt || "Type is required"],

    isValid: false,
    isSaving: false,    
  }),

  computed: {
    ...mapGetters("BudgetModule", [
      "transactionTypes",
      "budgetCategories",
      "transactionStatuses",
      "entryFormTransaction",
    ]),
    isSmallScreen() {
      const screenSizes = ['xs','sm'];
      return screenSizes.indexOf(this.$vuetify.breakpoint.name) >= 0;
    },
    transactionStatusesList() {
      return [...this.transactionStatuses];
    },
    showAllFields() {
      return !this.isSmallScreen || this.showFullForm;
    },
    tipAmountIsVisible() {
      return this.showAllFields || this.showTipAmount || this.transaction.tipAmount;
    },
    confirmationNumberIsVisible() {
      return this.showAllFields || this.showConfirmationNumber || this.transaction.confirmationNumber;
    },
    checkNumberIsVisible() {
      return this.showAllFields || this.showCheckNumber || this.transaction.checkNumber;
    }

  },
  methods: {
    ...mapActions("BudgetModule", [
      "closeTransactionForm",
    ]),
    ...mapActions("MessageHubModule", [
      "sendBudgetTransactionAdded",
      "sendBudgetTransactionEdited",
    ]),    
    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      }
    },
    getBudgetCategoryName(id) {
      const category = this.budgetCategories.find(bc => bc.id === id);
      return category ? category.name : '';
    },
    close() {
      this.showFullForm = false;
      this.showCheckNumber = false;
      this.showTipAmount = false;
      this.showConfirmationNumber = false;
      this.resetForm();
      this.closeTransactionForm();
    },
    async save() {
      try {
        this.isSaving = true;
        this.$refs.form.validate();
        
        if (this.isValid) {
          let result;
          if (this.transaction.id > 0) {
            result = await budgetService.updateTransaction(this.transaction);
            this.sendBudgetTransactionEdited(result.data);
            toasterHandler.showToast('success', 'mdi-pencil', null, "Transaction updated", null, null, null);
          } else {
            await this.setTransactionCoords();
            result = await budgetService.addTransaction(this.transaction);
            this.sendBudgetTransactionAdded(result.data);
            toasterHandler.showToast('success', 'mdi-plus', null, "Transaction added", null, null, null);
          }
          this.$emit('saved', result.data);
          this.close();          
        }
      } catch(err) {
        toasterHandler.showToast('error', 'mdi-alert-circle-outline', null, "Error occurred while saving transaction", null, null, null);
      } finally {
        this.isSaving = false;
      }
    },
    
    focus() {
      if (this.transaction && !this.transaction.description) {
        this.$refs.description.$el.focus();
      }
    },
    async setTransactionCoords() {
      const coords = await geolocation.getLocation();
      if (coords) {
        this.transaction.createdLocationLatitude = coords.latitude;
        this.transaction.createdLocationLongitude = coords.longitude;
      }
    },
  },
  watch: {
    entryFormTransaction: {
      handler(val) {
        if (val) {
          val.transactionDate = new Date(val.transactionDate)
            .toISOString()
            .substr(0, 10);
        }
        this.transaction = { ...val };
      },
      deep: true,
    },
    isSmallScreen() {
      this.focus();
    }
  },
  mounted() {
    this.focus();
  },
  beforeDestroy() {
    document.body.classList.remove('dialog-is-open');
  }
};
</script>


<style scoped>
.category-name {
  font-size: 30px;
}
.transaction-date {
  font-size: 16px;
  padding-left: 12px;
}

.date-picker {
  flex-basis: 300px;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  min-width: 300px;
}
.date-picker .v-picker--date {
  min-height: 380px;
}

.v-dialog {
  position: relative;
}

.v-stepper__content .title {
  font-size: 1.4rem;
}

.detail {
  flex-basis: 400px;
}

.v-btn-toggle .v-btn {
  width:120px;
}

.previous-next button{
  width: 120px;
}

form {
  height: 100%;
}
</style>
