<template>
  <v-dialog
    max-width="800px"
    :value="value"
    @input="$emit('input', value)"
    scrollable
    tile
  >
    <v-card tile>
      <v-card-title class="pa-0">
        <v-toolbar color="primary" flat dark dense height="40px">
          <div>Transaction Detail</div>
          <v-spacer />
          <v-btn icon dark @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>

      <v-card-text class="px-0 pb-0">
        <div v-if="!isLocationEditVisible" class="d-flex flex-wrap">
          <div class="transaction-detail px-2">
            <div class="detail-group">
              <label>Date: </label>
              <span>{{ value.transactionDate | shortDate }}</span>
            </div>
            <div class="detail-group">
              <label>Description: </label>
              <span>{{ value.description }}</span>
            </div>
            <div class="detail-group">
              <label>Amount: </label>
              <span>{{ value.amount | currency }}</span>
            </div>
            <div v-if="value.tipAmount" class="detail-group">
              <label>Tip: </label>
              <span>{{ value.tipAmount | currency }}</span>
            </div>
            <div class="detail-group">
              <label>Category: </label>
              <span>{{ value.budgetCategoryName }}</span>
            </div>
            <div class="detail-group">
              <label>Type: </label>
              <span>{{ value.transactionTypeName }}</span>
            </div>
            <div class="detail-group">
              <label>Status: </label>
              <span>{{ value.transactionStatusName }}</span>
            </div>
            <div v-if="value.memo" class="detail-group">
              <label>Memo: </label>
              <span>{{ value.memo }}</span>
            </div>
            <div v-if="value.confirmationNumber" class="detail-group">
              <label>Conf #: </label>
              <span>{{ value.confirmationNumber }}</span>
            </div>

            <v-divider v-if="value.createdDateTime || value.createdBy" />

            <div v-if="value.createdBy" class="detail-group">
              <label>Created By: </label>
              <span class="created-by">{{ value.createdBy }}</span>
            </div>

            <div v-if="value.createdDateTime" class="detail-group d-flex">
              <label>Date Created: </label>
              <div>
                <div>{{ createdOffsetDate }}</div>
                <div>{{ createdOffsetTime }}</div>
              </div>
            </div>
          </div>

          <div class="map-container">
            <MapBox v-if="mapItems && isMapVisible" :mapItems="mapItems" />
          </div>
        </div>

        <div v-if="isLocationEditVisible" class="text-center pa-2 update-location">
          <div class="d-flex flex-wrap">
            <div class="map-container bordered my-2 mx-1 text-left">
              <MapBox
                v-if="updateLocationMapCoords && isMapVisible"
                :mapItems="updateLocationMapCoords"
              />
            </div>
            <div class="form-fields my-2 mx-1 flex-grow-1">
              <div class="title mb-2">Update Location</div>
              <v-text-field
                required
                v-model="latitude"
                type="number"
                autocomplete="off"
                label="Latitude"
                outlined
                dense
                hide-details
                class="mb-2"
                @change="onLatitudeChange"
              ></v-text-field>

              <v-text-field
                required
                v-model="longitude"
                type="number"
                autocomplete="off"
                label="Longitude"
                outlined
                dense
                hide-details
                class="mb-2"
                @change="onLongitudeChange"
              ></v-text-field>

              <v-text-field
                v-model="latLong"
                type="text"
                autocomplete="off"
                label="Latitude, Longitude"
                outlined
                dense
                hide-details
                class="mb-2"
                @change="onLatLongChange"
              ></v-text-field>

              <v-btn @click="setCurrentLocation()">
                <v-icon>mdi mdi-crosshairs</v-icon>
                <span class="ml-1">Current Location</span>
              </v-btn>
            </div>
          </div>
        </div>
      </v-card-text>

      <v-card-actions class="justify-space-between">
        <template v-if="!isLocationEditVisible">
          <v-btn @click="close()" class="mx-1">Close</v-btn>

          <v-btn
            @click="showLocationUpdate()"
            :loading="isSettingCurrentLocation"
            class="mx-1"
          >
            <v-icon>mdi mdi-map-marker</v-icon>
            <span>Update Location</span>
          </v-btn>
        </template>

        <template v-if="isLocationEditVisible">
          <v-btn
            text
            @click="cancelLocationUpdate()"
            :disabled="isSavingLocationUpdate"
            class="mx-1"
            >Cancel</v-btn
          >
          <v-btn
            text
            @click="saveLocationUpdate()"
            :loading="isSavingLocationUpdate"
            class="mx-1"
            >Save</v-btn
          >
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import MapBox from "@/components/MapBox/MapBox.vue";
import budgetService from "@/services/budget.service.js";
import geolocation from "@/utils/geolocation.js";
import toasterHandler from "@/utils/toaster.handler.js";

export default {
  name: "TransactionDetailModal",

  props: ["value"],

  components: {
    MapBox,
  },

  data: () => ({
    isLocationEditVisible: false,
    isSettingCurrentLocation: false,
    latitude: "",
    longitude: "",
    latLong: "",
    isSavingLocationUpdate: false,
    isMapVisible: true,
  }),

  computed: {
    createdOffsetDateTime() {
      let dateTime = moment(this.value.createdDateTime);
      dateTime.add(-this.value.createdUtcOffset, "minutes");

      return `${dateTime.format("MMMM Do YYYY, h:mm:ss a")} ${
        this.value.createdTimeZone
      }`;
    },
    createdOffsetDate() {
      let dateTime = moment(this.value.createdDateTime);
      dateTime.add(-this.value.createdUtcOffset, "minutes");

      return `${dateTime.format("MMMM Do YYYY")}`;
    },
    createdOffsetTime() {
      let dateTime = moment(this.value.createdDateTime);
      dateTime.add(-this.value.createdUtcOffset, "minutes");

      return `${dateTime.format("h:mm:ss a")} ${this.value.createdTimeZone}`;
    },
    mapItems() {
      return this.value.createdLocationLatitude && this.value.createdLocationLongitude
        ? [
            {
              latitude: this.value.createdLocationLatitude,
              longitude: this.value.createdLocationLongitude,
            },
          ]
        : null;
    },
    updateLocationMapCoords() {
      return this.isMapVisible && this.latitude && this.longitude
        ? [
            {
              latitude: this.latitude,
              longitude: this.longitude,
            },
          ]
        : null;
    },
  },
  methods: {
    close() {
      this.$emit("input", "");
    },
    setLatLong() {
      this.latLong = `${this.value.createdLocationLatitude},${this.value.createdLocationLongitude}`;
    },
    redrawMap() {
      this.isMapVisible = false;
      setTimeout(() => {
        this.isMapVisible = true;
      }, 1);
    },
    showLocationUpdate() {
      this.isLocationEditVisible = true;

      this.latitude = this.value.createdLocationLatitude;
      this.longitude = this.value.createdLocationLongitude;
      this.redrawMap();
    },
    cancelLocationUpdate() {
      this.latitude = null;
      this.longitude = null;
      this.isLocationEditVisible = false;
    },
    async saveLocationUpdate() {
      try {
        this.isSavingLocationUpdate = true;
        let updated = await budgetService.updateTransactionLocation(
          this.value.id,
          this.latitude,
          this.longitude
        );
        this.isLocationEditVisible = false;
        this.redrawMap();
        this.$emit("saved", updated);
        toasterHandler.showToast('success', 'mdi-pencil', null, "Location updated", null, null, null);
      } finally {
        this.isSavingLocationUpdate = false;
      }
    },
    async setCurrentLocation() {
      try {
        this.isSettingCurrentLocation = true;

        const coords = await geolocation.getLocation();
        if (coords) {
          this.latitude = coords.latitude;
          this.longitude = coords.longitude;
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.isSettingCurrentLocation = false;
        this.redrawMap();
      }
    },
    getLatLongCoords() {
      const isValidCoords = this.latLong.split(',').length === 2 && !isNaN(this.latLong.split(',')[0]) && !isNaN(this.latLong.split(',')[1]);
      if (!isValidCoords) {
        return;
      }
      return { latitude: this.latLong.split(',')[0].trim(), longitude: this.latLong.split(',')[1].trim()};
    },
    onLatitudeChange() {
      let updatedLatLong = this.getLatLongCoords();
      this.latLong = `${this.latitude},${updatedLatLong.longitude}`;
    },
    onLongitudeChange() {
      let updatedLatLong = this.getLatLongCoords();
      this.latLong = `${updatedLatLong.latitude},${this.longitude}`;
    },
    onLatLongChange() {
      const isValidCoords = this.latLong.split(',').length === 2 && !isNaN(this.latLong.split(',')[0].trim()) & !isNaN(this.latLong.split(',')[1].trim());
      if (!isValidCoords) {
        return;
      }
      

      this.latitude = this.latLong.split(',')[0].trim();
      this.longitude = this.latLong.split(',')[1].trim();      
      this.latLong = `${this.latitude},${this.longitude}`;
    }
  },
  mounted() {
    window.addEventListener("resize", this.redrawMap);
    this.setLatLong();
  },
  watch: {
    value() {
      this.setLatLong();
    }
  },
};
</script>

<style scoped>
.detail-group {
  margin: 5px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-size: 1em;
  display: flex;
}

.detail-group label {
  flex-basis: 110px;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: right;
  margin-right: 16px;
  font-weight: bold;
}

.detail-group span {
  white-space: pre-wrap;
}

a {
  text-decoration: none;
}

.transaction-detail {
  flex-basis: 225px;
  flex-shrink: 1;
  flex-grow: 1;
  order: 2;
}
.map-container {
  flex-basis: 200px;
  flex-grow: 1;
  flex-shrink: 1;
  order: 1;
  max-height: calc(100% - 160px);
  max-width: 100%;
  min-height: 250px;
  /* border: 1px solid #ccc; */
}

.bordered {
  border: 1px solid #ccc;
}

.update-location .map-container {
  flex-basis: 300px;
  flex-grow: 1;
  /* flex-shrink: 1; */
  order: 1;
  height: calc(100vh - 200px);
  max-height: 500px;
  max-width: 100%;
}

.update-location .form-fields {
  order: 2;
  flex-basis: 100px;
}
</style>
