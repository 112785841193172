import axios from '@/plugins/axios.js';

export default {
    getRecurringTransactions() {
        const url = `/RecurringTransactions`;
        return axios.get(url);
    },
    async addRecurringTransaction(t) {
        const url = `/RecurringTransactions`;

        const x = {
            id: null,
            recurranceNumber: t.recurranceNumber,
            description: t.description,
            amount: t.amount,
            budgetCategoryId: t.budgetCategoryId,
            transactionTypeId: t.transactionTypeId,
            memo: t.memo,
            recurranceTypeId: t.recurranceTypeId,
            startDate: t.startDate,
            isAutopay: t.isAutopay,
            isActive: t.isActive,
            transactionType: null,
            budgetCategory: null,
            recurranceType: null
        };
    
        return axios.post(url, x);
    },
    async updateRecurringTransaction(t) {
        const url = `/RecurringTransactions`;

        const x = {
            id: t.id,
            recurranceNumber: t.recurranceNumber,
            description: t.description,
            amount: t.amount,
            budgetCategoryId: t.budgetCategoryId,
            transactionTypeId: t.transactionTypeId,
            memo: t.memo,
            isActive: t.isActive,
            isAutopay: t.isAutopay,
            recurranceTypeId: t.recurranceTypeId,
            startDate: t.startDate,            
            transactionType: null,
            budgetCategory: null,
            recurranceType: null
        };    

        return axios.put(url, x);
    },
    deleteRecurringTransaction(transaction) {
        const url = `/RecurringTransactions/${transaction.id}`;
        return axios.delete(url);
    }
}