<template>
    <v-expansion-panels>
        <v-expansion-panel v-if="transactions" dense>
            <v-expansion-panel-header class="pa-1">
                <div class="d-flex flex-wrap summary-header">
                    <div class="text-align">
                        <label>Budget Bal.</label>
                        <div v-if="!isLoading">{{remainingTotalBudget | currency}}</div>
                    </div>
                    
                    <div class="text-align">
                        <label>Available Funds</label>
                        <div v-if="!isLoading">{{availableFunds | currency}}</div>
                    </div>
                        
                    <div class="text-align">
                        <label>Bank Bal.</label>
                        <div>{{bankAccountBalance | currency}}</div>
                    </div>
                </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-divider />
                <div class="d-flex justify-center flex-wrap">
                    <div class="d-flex justify-center flex-wrap">
                        <!-- Balances -->
                        <div class="summary-group">
                            <div class="summary-group-header">Current Balances</div>

                            <div class="summary-group-item">
                            <label>Actual Spent:</label>
                            <span v-if="!isLoading">{{actualSpent | currency}}</span>
                            </div>

                            <div class="summary-group-item">
                            <label>Actual Remaining:</label>
                            <span v-if="!isLoading">{{actualRemaining | currency}}</span>
                            </div>
                        </div>

                        <!-- Budgets -->
                        <div class="summary-group">
                            <div class="summary-group-header">Budget</div>
                            <div class="summary-group-item">
                            <label>Total:</label>
                            <span v-if="!isLoading">{{startingTotalBudget | currency}}</span>
                            </div>

                            <div class="summary-group-item">
                            <label>Spent:</label>
                            <span v-if="!isLoading" class="summary-amount">{{totalBudgetSpent | currency}}</span>
                            </div>

                            <div class="summary-group-item">
                            <label>Remaining: </label>
                            <span v-if="!isLoading" class="summary-amount">{{remainingTotalBudget | currency}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex justify-center flex-wrap">
                        <!-- Income -->
                        <div class="summary-group">
                            <div class="summary-group-header">Income</div>

                            <div class="summary-group-item">
                            <label>Starting Balance:</label>
                            <span v-if="!isLoading">{{startingBalance | currency}}</span>
                            </div>

                            <div class="summary-group-item">
                            <label>Total:</label>
                            <span v-if="!isLoading">{{totalIncome | currency}}</span>
                            </div>

                            <div class="summary-group-item">
                            <label>Deposited:</label>
                            <span v-if="!isLoading">{{depositedIncome | currency}}</span>
                            </div>
                            
                            <div class="summary-group-item">
                            <label>Savings:</label>
                            <span v-if="!isLoading">{{savingsAmount | currency}}</span>
                            </div>
                        </div>

                        <!-- Bills -->
                        <div class="summary-group">
                            <div class="summary-group-header">Bills</div>

                            <div class="summary-group-item">
                            <label>Total:</label>
                            <span v-if="!isLoading">{{ -totalBills | currency }}</span>
                            </div>

                            <div class="summary-group-item">
                            <label>Bills Paid:</label>
                            <span v-if="!isLoading">{{ -paidBills | currency }}</span>
                            </div>

                            <div class="summary-group-item">
                            <label>Bills Unpaid:</label>
                            <span v-if="!isLoading">{{ -totalBills - -paidBills | currency }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>    
</template>

<script>
import { TRANSACTION_STATUSES } from '../../constants/transaction-statuses';

export default {
    name: "BudgetSummary",

    props: [
        "isLoading",
        "transactions",
        "spendLimits",
        "startDate",
        "startingBalance",
        "bankAccountBalance"
    ],
    components: {
    }, 

    data: () => ({
    }),

    computed: {
        totalIncome() {
            return this.transactions
                .filter(t => t.budgetCategoryName === 'Income')
                .reduce((total, t) => total + ((t.transactionTypeName === 'Debit') ? (- t.amount) : (+ t.amount)), 0);
        },
        totalBills() {
            return this.transactions
                .filter(at => at.budgetCategoryName === 'Bills')
                .reduce((total, t) => total + ((t.transactionTypeName === 'Debit') ? (- t.amount) : (+ t.amount)), 0);
        },
        depositedIncome() {
            return this.transactions
                .filter(t => t.budgetCategoryName === 'Income' && t.transactionStatusId >= TRANSACTION_STATUSES.sent)
                .reduce((total, t) => total + ((t.transactionTypeName === 'Debit') ? (- t.amount) : (+ t.amount)), 0);
        },
        paidBills() {
            return this.transactions
                .filter(t => t.budgetCategoryName === 'Bills' && t.transactionStatusId >= TRANSACTION_STATUSES.sent)
                .reduce((total, t) => total + ((t.transactionTypeName === 'Debit') ? (- t.amount) : (+ t.amount)), 0);
        },
        actualSpent() {
            return this.transactions
                .filter(t => t.budgetCategoryName !== 'Income' && t.budgetCategoryName !== 'Savings' && t.transactionStatusId >= TRANSACTION_STATUSES.sent)
                .reduce((total, t) => total + ((t.transactionTypeName === 'Debit') ? (- t.amount) : (+ t.amount)), 0);
        },
        savingsAmount() {
            return this.transactions
                .filter(t => t.budgetCategoryName === 'Savings' && t.transactionStatusId >= TRANSACTION_STATUSES.sent)
                .reduce((total, t) => total + ((t.transactionTypeName === 'Debit') ? (t.amount) : (- t.amount)), 0);
        },
        processedTransactionAmountTotal() {
            return this.transactions
                .filter(t => t.transactionStatusId >= TRANSACTION_STATUSES.pend)
                .reduce((total, t) => total + ((t.transactionTypeName === 'Debit') ? (- t.amount) : (+ t.amount)), 0);
        },
        enteredTransactionsTotal() {
            return this.transactions
                .reduce((total, t) => total + ((t.transactionTypeName === 'Debit') ? (- t.amount) : (+ t.amount)), 0);
        },
        startingTotalBudget() {
            return !this.spendLimits || this.spendLimits.length === 0
                ? null
                : this.spendLimits.map(bpc => bpc.amount).reduce((total, t) => total + (t));
        },
        remainingTotalBudget() {
            if (!this.spendLimits || this.spendLimits.length === 0 || !this.transactions) {
                return null;
            }

            let sum = 0;

            this.spendLimits.map(cb => cb.budgetCategoryId)
                .forEach(categoryId => {
                    sum += this.transactions
                        .filter(ct => ct.budgetCategoryId === categoryId)
                        .reduce((total, t) => total + ((t.transactionTypeName === 'Debit') ? (- t.amount) : (+ t.amount)), 0)
                });

            return this.startingTotalBudget + sum;
        }, 
        totalBudgetSpent() {
            return this.startingTotalBudget - this.remainingTotalBudget;
        },
        availableFunds() {
            return this.startingBalance + this.enteredTransactionsTotal;
        },
        actualRemaining() {
            return this.startingBalance + this.depositedIncome + this.actualSpent;
        },
        unpaidBills() {
            return (-this.totalBills) - (-this.paidBills);
        }
    },
    methods: {        
    },
    watch: {
        

    },
    async mounted() {        
        
    }
}
</script>

<style scoped>
 .summary-group {
    padding: 10px;
    min-width: 250px;
  }

  .summary-group .summary-group-header {
    text-align: center;
    font-size: 18px;
    padding-bottom:3px;
    margin-bottom: 3px;
    border-bottom: 1px solid #ccc;    
  }
  .summary-group label {
    font-weight: bold;
    display: inline-block;
    padding: 2px;
    width: 160px;
    text-align: left;
    margin-right: 8px;
  }
  
  .summary-group-item {
      text-align: left;
      font-size: 14px;
  }

  .summary-group-item span {
    float: right;
  }

  .summary-header {
      justify-content: space-around;
      font-size: 15px;
      color: rgba(0,0,0,.54);
  }

  @media (max-width: 550px) {
    
    .summary-header {    
        justify-content: space-between;
    }
    
  }
</style>