<template>
  <v-sheet color="white" elevation="1" class="pa-2">
    <div class="selected-date">
      <span>{{ timeFrame.startDate | monthName }} </span>
      <span
        >{{ timeFrame.startDate | dateNumber }} -
        {{ timeFrame.endDate | dateNumber }},
      </span>
      <span>{{ timeFrame.startDate | year }}</span>
    </div>

    <div class="d-flex justify-center">
      <v-btn icon class="mx-3" @click.stop="selectPrevious()">
        <v-icon>mdi-arrow-left-bold</v-icon>
      </v-btn>

      <v-btn icon class="mx-3" @click.stop="datePickerDialog = true">
        <v-icon>mdi-calendar-today</v-icon>
      </v-btn>     

      <v-btn text class="mx-3" @click.stop="selectCurrent()">
        Today
      </v-btn>

      <v-btn icon class="mx-3" @click.stop="openSearch()">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

      <v-btn icon class="mx-3" @click.stop="viewMap()">
        <v-icon>mdi-map</v-icon>
      </v-btn>

      <v-btn icon class="mx-3" @click.stop="selectNext()">
        <v-icon>mdi-arrow-right-bold</v-icon>
      </v-btn>
    </div>


    <template v-if="datePickerDialog">
        <v-dialog v-model="datePickerDialog" width="360px">
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Select Budget Date
            </v-card-title>

            <v-card-text class="pt-6 pb-4">
              <v-row justify="center">
                <v-date-picker v-model="datePickerValue" @change="onDatePickerChange"></v-date-picker>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click.stop="datePickerDialog = false">
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
  </v-sheet>
</template>

<script>
import moment from "moment";

export default {
  name: "BudgetDateSelector",

  components: {},

  data: () => ({
    date: new Date(),
    datePickerDialog: false,
    datePickerValue: '',

    selectedBudgetDate: "",
    timeFrame: {},
  }),

  computed: {
    lastDayOfMonth() {
      return this.selectedBudgetDate.endOf("month").date();
    },
  },

  methods: {
    toggleMenu() {
      this.$emit("openMenu");
    },
    onDatePickerChange() {
      let selected = moment(this.datePickerValue);
      console.log(selected);

      let x = selected.date() < 15
          ? moment([selected.year(), selected.month(), 1])
          : moment([selected.year(), selected.month(), 15]);

      this.selectedBudgetDate = x;
      this.setTimeFrame();
      this.datePickerDialog = false;
    },
    selectPrevious() {
      const date = this.selectedBudgetDate.date();
      if (date < 15) {
        this.selectedBudgetDate.subtract(1, "month").date(15); // set to 15th of previous month
      } else {
        this.selectedBudgetDate.date(1); // set to first of month
      }

      this.setTimeFrame();
    },
    selectCurrent() {
      let x = moment();

      x = x.date() < 15
          ? moment([x.year(), x.month(), 1])
          : moment([x.year(), x.month(), 15]);

      this.selectedBudgetDate = x;
      this.setTimeFrame();

    },
    selectNext() {
      if (this.selectedBudgetDate.date() < 15) {
        this.selectedBudgetDate.date(15);
      } else {
        this.selectedBudgetDate.add(1, "month").date(1);
      }
      this.setTimeFrame();
    },
    setTimeFrame() {
      let startDate;
      let endDate;

      if (this.selectedBudgetDate.date() < 15) {
        startDate = moment([
          this.selectedBudgetDate.year(),
          this.selectedBudgetDate.month(),
          1,
        ]).format("YYYY-MM-DD");
        endDate = moment([
          this.selectedBudgetDate.year(),
          this.selectedBudgetDate.month(),
          14,
        ]).format("YYYY-MM-DD");
      } else {
        startDate = moment([
          this.selectedBudgetDate.year(),
          this.selectedBudgetDate.month(),
          15,
        ]).format("YYYY-MM-DD");
        endDate = moment([
          this.selectedBudgetDate.year(),
          this.selectedBudgetDate.month(),
          this.selectedBudgetDate.endOf("month").date(),
        ]).format("YYYY-MM-DD");
      }

      this.timeFrame = { startDate, endDate };
      this.datePickerValue = startDate;
    },
    openSearch() {
      this.$emit("openSearch");
    },
    viewMap() {
      this.$emit("viewMap");
    }
  },
  watch: {
    timeFrame() {
      this.$emit("dateSelected", this.timeFrame);
    },
    datePickerDialog() {
      this.datePickerValue = moment().format('yyyy-MM-DD');
    }
  },
  mounted() {
    this.selectCurrent();

    this.datePickerValue = moment().format('yyyy-MM-DD');
  },
};
</script>

<style scoped>
.selected-date {
  font-size: 24px;
}
</style>
